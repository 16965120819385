import { motion } from "framer-motion";
import styled from "styled-components";
import { Section } from "../../globalStyles";

export const InfoSection = styled(Section)`
  padding: 150px 0 0;

  @media screen and (max-width: 940px) {
    padding: 5px 30px;
    padding-top: 50px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(20, 0, 0, 0.6)),
      url("./assets/images/darka_backdrop.jpg");
    background-size: contain;
  }
`;
export const InfoRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 5rem;
  grid-auto-columns: 2rem;

  @media screen and (max-width: 940px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 100px;

    > div:first-child {
      display: none;
    }
  }
`;

export const InfoColumn = styled(motion.div)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 940px) {
    align-items: center;
  }
`;

export const Image = styled.div`
  height: 700px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 2)),
    url("./assets/images/darka_backdrop.jpg");
  background-size: contain;
  display: flex;
  align-items: center;
  object-fit: fill;
  margin-left: 20px;
`;

export const InfoHeading = styled(motion.h2)`
  margin: 0rem 0 2rem;
  font-size: clamp(2.1rem, 7vw, 3rem);
  line-height: 1.1;
  font-weight: 600;
  color: ${({ inverse }) => (inverse ? "black" : "pink")};

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const InfoDesc = styled(motion.div)`
  margin-bottom: 3rem;
  color: ${({ inverse }) => (inverse ? "black" : "white")};
  font-size: 1.1rem;
  font-size: clamp(1rem, 2vw, 1.1rem);

  @media screen and (max-width: 768px) {
    text-align: center;
    color: ${({ inverse }) => (inverse ? "black" : "white")};
    font-weight: 350;
  }
`;

export const InfoWrapper = styled(motion.div)`
  padding-left: 4rem;
  padding-right: 2rem;
  padding-top: 2rem;
  max-width: 540px;
  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    backdrop-filter: blur(6px);
    font-weight: 500;
    border-radius: 20px;
    margin: 0 auto;
  }
`;

export const BottomLine = styled.div`
  display: flex;
  gap: 4rem;
  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const InfoNumber = styled.div`
  font-weight: 700;
  font-size: 1.3rem;
  letter-spacing: 0.04rem;
`;

export const InfoText = styled.div`
  font-weight: 500;
  margin-top: 0.4rem;
  color: #b02929;
`;
