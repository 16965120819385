export const navbarData = [
	{
		text: 'About',
		id: 'about',
		spy: true,
		duration: 500,
		smooth: true,
		exact: 'true',
		offset: -80,
		to: 'about',
	},
	{
		text: 'Events',
		spy: true,
		duration: 500,
		smooth: true,
		exact: 'true',
		offset: -80,
		to: 'events',
	},
	{
		text: 'Contact Us',
		spy: true,
		duration: 500,
		smooth: true,
		exact: 'true',
		offset: -80,
		to: 'contactus',
	},
];
