import React, { useState } from "react";
import { Container } from "../../globalStyles";
import {
  FormColumn,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormLabel,
  FormButton,
  FormTitle,
  FormMessage,
} from "./FormStyle";
import validateForm from "./validateForm";

function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [message, setMessage] = useState("");

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const resultError = validateForm({ name, email, phoneNo, message });

    if (resultError !== null) {
      setError(resultError);
      return;
    }
    setName("");
    setEmail("");
    setPhoneNo("");
    setMessage("");
    setError(null);
    setSuccess("Message was sent Successfully");
  };

  return (
    <FormSection className="contactus">
      <Container>
        <FormRow>
          <FormColumn small>
            <FormTitle>Contact Us</FormTitle>
            <FormWrapper onSubmit={handleSubmit}>
              <FormLabel>Full Name</FormLabel>
              <FormInput
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <FormLabel>Email Address</FormLabel>
              <FormInput
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <FormLabel>Phone Number</FormLabel>
              <FormInput
                type="number"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />

              <FormLabel>Message</FormLabel>
              <FormInput
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <FormButton type="submit">Send Message</FormButton>
            </FormWrapper>
            {error && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
                error
              >
                {error}
              </FormMessage>
            )}
            {success && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {success}
              </FormMessage>
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
}

export default Form;
