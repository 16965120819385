import React from "react";
import Footer from "../components/Footer/Footer";
import MembersNavbar from "../components/Navbar/MembersNavbar";

function MemberPortalMainPage() {
  return (
    <>
      <MembersNavbar />
      <Footer />
    </>
  );
}

export default MemberPortalMainPage;
