import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import Navbar from "../Navbar/Navbar";
import {
  HeroSection,
  HeroContent,
  HeroImage,
  HeroSlide,
  HeroSlider,
  HeroWrapper,
} from "./HeroInfoStyle";

const Hero = ({ slides }) => {

    const { ref, inView } = useInView({
        rootMargin: "-80px",
      });
    
      const [current, setCurrent] = useState(0);
      const length = slides.length;
      const timeout = useRef(null);
    
      useEffect(() => {
        const nextSlide = () => {
          setCurrent((current) => (current === length - 1 ? 0 : current + 1));
        };
    
        timeout.current = setTimeout(nextSlide, 3000);
    
        return function () {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
        };
      }, [current, length]);
    
      if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
      }

  return (
    <>
        <HeroSection ref={ref} id="hero">
        <HeroWrapper>
        {slides.map((slide, index) => {
          return (
            <HeroSlide key={index}>
              {index === current && (
                <HeroSlider>
                  <HeroImage src={slide.image} alt={slide.alt} />
                   <HeroContent>
                    <h1>{slide.title}</h1>
                  </HeroContent> 
                </HeroSlider>  
              )}
            </HeroSlide>
          );
        })}
      </HeroWrapper>
        </HeroSection>
        <Navbar hide={inView} />
    </>
  )
};

export default Hero;
