import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./LoginRegister.css";
import {
  InputField,
  InputLabel,
  LoginButton,
  LoginContainer,
  LoginContainerHeading,
  LoginContainerText,
  LoginLogo,
  LoginRegisterContainer,
  RegisterButton,
} from "./LoginRegisterStyle";

function Login() {
  const [fullname, setFullname] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const register = (e) => {
    e.preventDefault();
  };

  return (
    <LoginRegisterContainer>
      <Link to="/">
        <LoginLogo
          className="login__logo"
          src="./assets/logo__edit.png"
          alt=""
        />
      </Link>
      <LoginContainer className="login__container">
        <LoginContainerHeading>Create an Account</LoginContainerHeading>

        <form>
          <InputLabel>Full Name</InputLabel>
          <InputField
            type="text"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
          />

          <InputLabel>Phone Number</InputLabel>
          <InputField
            type="text"
            value={phoneno}
            onChange={(e) => setPhoneno(e.target.value)}
          />

          <InputLabel>E-mail</InputLabel>
          <InputField
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <InputLabel>Password</InputLabel>
          <InputField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Link to="/member-main-page">
            <LoginButton>Create your Darka Forex Account</LoginButton>
          </Link>
        </form>

        <LoginContainerText>
          Your data will not be shared with any third parties. All data
          collected is used by Darka Forex Wealth for verification purposes
          only. By registering an account you agree to our disclaimer.
        </LoginContainerText>

        <Link to="/login-page">
          <RegisterButton className="login__registerButton">
            Already have an Account?<strong> Login..</strong>
          </RegisterButton>
        </Link>
      </LoginContainer>
    </LoginRegisterContainer>
  );
}

export default Login;
