export const heroMain = {
  reverse: true,
  inverse: false,
  topLine: {
    // text: 'Free',
    // location: 'Park City, Utah',
  },
  headline: "Find the best events",
  description:
    "Welcome New Year with Wondergarden on waterfront. Celebrate with world-class international acts, roaming performers, children’s performers and entertainment",
  price: "R750.00",
  sizes: "S-M-L-XL-2XL-3XL",
  buttonLabel: "Find out more",
  imgStart: "start",
  img: "./assets/images/hoodycut.png",
  start: "true",
};

const heroOne = {
  reverse: true,
  inverse: "true",
  topLine: {
    text: "In Stock",
    location: "Park City, Utah",
  },
  headline: "Charts & Charts",
  price: "R750.00",
  sizes: "S-M-L-XL-2XL-3XL",
  description:
    "Charts & ChartsCharts & ChartsCharts & ChartsCharts & ChartsCharts & ChartsCharts & ChartsCharts & ChartsCharts & ChartsCharts & Charts",
  buttonLabel: "Find out more",
  imgStart: "start",
  img: "./assets/images/chart_chart.jpg",
  start: "true",
};

const heroTwo = {
  reverse: false,
  inverse: "false",
  topLine: {
    text: "In Stock",
    location: "Hard Rock Stadium, Miami, Florida",
  },
  headline: "Beginner to Advanced Trader",
  description:
    "Beginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced TraderBeginner to Advanced Trader",
  buttonLabel: "Find out more",
  price: "CAP - R80.00 ; HAT - R80.00",
  sizes: "One Size Fits All",
  imgStart: "start",
  img: "./assets/images/teacher.jpg",
  start: "true",
};

const heroThree = {
  reverse: true,
  inverse: "true",
  topLine: {
    location: "New Orleans, Louisiana",
    text: "In Stock",
  },
  headline: "Live Video Demos",
  description:
    "Live Video DemosLive Video DemosLive Video DemosLive Video DemosLive Video DemosLive Video DemosLive Video DemosLive Video DemosLive Video DemosLive Video Demos",
  buttonLabel: "Find out more",
  price: "R560.00",
  sizes: "S-M-L-XL-2XL-3XL",
  imgStart: "",
  img: "./assets/images/live_chart.jpg",
  start: "true",
};

const heroFour = {
  reverse: false,
  inverse: "false",
  topLine: {
    location: "Augusta National Gold Club, Georgia",
    text: "In Stock",
  },
  headline: "Signals & Past Sessions",
  description:
    "Signals & Past SessionsSignals & Past SessionsSignals & Past SessionsSignals & Past SessionsSignals & Past SessionsSignals & Past SessionsSignals & Past SessionsSignals & Past SessionsSignals & Past Sessions",
  buttonLabel: "Find out more",
  price: "R750.00",
  sizes: "S-M-L-XL-2XL-3XL",
  imgStart: "",
  img: "./assets/images/phone_pc_pic.jpg",
  start: "true",
};

export const heroData = [heroOne, heroTwo, heroThree, heroFour];
