import React, { useEffect } from "react";
import { infoData } from "./AboutUsData";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

import {
  InfoSection,
  Image,
  InfoWrapper,
  InfoDesc,
  InfoRow,
  InfoColumn,
  InfoHeading,
} from "./AboutUsStyle";

const AboutUs = ({ id }) => {
  const { headline, description } = infoData;

  const initial = { opacity: 0, scale: 0.3 };
  const transition = { delay: 0.3, duration: 0.6 };
  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        scale: 1,
      });

      return;
    }

    animation.start({
      opacity: 0,
      scale: 0.3,
    });
  }, [inView, animation]);

  return (
    <InfoSection id={id} ref={ref}>
      <InfoRow>
        <InfoColumn
          initial={initial}
          transition={{ ...transition, delay: 0.3 }}
          animate={animation}
        >
          <Image src="" alt="" />
        </InfoColumn>
        <InfoColumn>
          <InfoWrapper>
            <InfoHeading
              initial={initial}
              transition={{ ...transition, delay: 0.5 }}
              animate={animation}
            >
              {headline}
            </InfoHeading>
            <InfoDesc
              initial={initial}
              transition={{ ...transition, delay: 0.7 }}
              animate={animation}
            >
              {description}
            </InfoDesc>
          </InfoWrapper>
        </InfoColumn>
      </InfoRow>
    </InfoSection>
  );
};

export default AboutUs;
