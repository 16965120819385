import React from "react";
import Form from "../components/contactus/FormPage";
import { HeroData } from "../components/Hero/HeroData";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/HeroInfo";
import AboutUs from "../components/aboutus/AboutUs";
import Content from "../components/Content/Content";
import { heroData } from "../components/Content/ContentData";

const LandingPage = () => {
  return (
    <>
      <Hero slides={HeroData} />
      <AboutUs />
      {heroData.map((contentData, index) => (
        <Content {...contentData} key={index} />
      ))}
      <Form />
      <Footer />
    </>
  );
};

export default LandingPage;
