export default function validateForm({ name, email, phoneNo, message }) {
  if (!name.trim()) {
    return "Username required";
  }
  // else if (!/^[A-Za-z]+/.test(name.trim())) {
  //   errors.name = 'Enter a valid name';
  // }

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return "Email required";
  } else if (regex.test(email.toLocalLowerCase)) {
    return "Email address is invalid";
  }
  if (!phoneNo) {
    return "Phone number is required";
  } else if (phoneNo.length < 10) {
    return "Phone number needs to be 10 numbers";
  }

  if (!message) {
    return "You cannot send an empty message";
  }
  return null;
}
