import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalStyle from "./globalStyles";
import LandingPage from "./pages/LandingPage";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import ProfilePage from "./pages/ProfilePage";
import ResourcePage from "./pages/ResourcePage";
import MemberPortalMainPage from "./pages/MemberPortalMainPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<LandingPage />} />

          <Route path="/login-page" element={<Login />} />

          <Route path="/register-page" element={<Register />} />

          <Route path="/profile-page" element={<ProfilePage />} />

          <Route path="/resource-page" element={<ResourcePage />} />

          <Route path="/member-main-page" element={<MemberPortalMainPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
