export const membersNavbarData = [
  {
    text: "Charts",
    id: "charts",
    spy: true,
    duration: 500,
    smooth: true,
    exact: "true",
    offset: -80,
    to: "charts",
  },
  {
    text: "Training",
    spy: true,
    duration: 500,
    smooth: true,
    exact: "true",
    offset: -80,
    to: "training",
  },
  {
    text: "Resources",
    spy: true,
    duration: 500,
    smooth: true,
    exact: "true",
    offset: -80,
    to: "/resource-page",
  },
];
