import React from "react";
import Footer from "../components/Footer/Footer";
import MembersNavbar from "../components/Navbar/MembersNavbar";

function ResourcePage() {
  return (
    <>
      <MembersNavbar />
      <h1 style={{ color: "white" }}>ResourcePage</h1>
      <Footer />
    </>
  );
}

export default ResourcePage;
