import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { CgMenuRight } from "react-icons/cg";
import { IconContext } from "react-icons";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavbarUserName,
  NavbarSignOut,
  NavbarSignoutButton,
  NavLinks,
  NavItem,
} from "./MembersNavbarStyle";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-router-dom";
import { NavButton } from "../../globalStyles";
import { membersNavbarData } from "./MembersNavbarData";

const NavbarOrders = ({ hide }) => {
  const [show, setShow] = useState(false);

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to="/" onClick={scroll.scrollToTop}>
          <NavIcon src="./assets/logo__edit.png" alt="" />
        </NavLogo>

        <Link>
          <NavbarSignOut>
            <NavbarUserName>Hi, Guest</NavbarUserName>
            <NavbarSignoutButton>Sign Out</NavbarSignoutButton>
          </NavbarSignOut>
        </Link>

        {!hide && (
          <>
            <IconContext.Provider value={{ color: "white" }}>
              <MobileIcon onClick={() => setShow(!show)}>
                {show ? <FaTimes /> : <CgMenuRight />}
              </MobileIcon>
            </IconContext.Provider>
            <NavMenu hide={hide} show={show}>
              {membersNavbarData.map((el, index) => (
                <NavItem key={index}>
                  <NavLinks
                    spy={true}
                    duration={500}
                    smooth={true}
                    exact="true"
                    offset={-79}
                    onClick={() => setShow(false)}
                    to={el.to}
                  >
                    {el.text}
                  </NavLinks>
                </NavItem>
              ))}
              <Link to="/profile-page">
                <NavButton>Profile</NavButton>
              </Link>
            </NavMenu>
          </>
        )}
      </NavbarContainer>
    </Nav>
  );
};

export default NavbarOrders;
