import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./LoginRegister.css";
import {
  InputField,
  InputLabel,
  LoginButton,
  LoginContainer,
  LoginContainerHeading,
  LoginContainerText,
  LoginLogo,
  LoginRegisterContainer,
  RegisterButton,
} from "./LoginRegisterStyle";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (e) => {
    e.preventDefault();
  };

  return (
    <LoginRegisterContainer>
      <Link to="/">
        <LoginLogo
          className="login__logo"
          src="./assets/logo__edit.png"
          alt=""
        />
      </Link>
      <LoginContainer>
        <LoginContainerHeading>Sign-in</LoginContainerHeading>

        <form>
          <InputLabel>E-mail</InputLabel>
          <InputField
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <InputLabel>Password</InputLabel>
          <InputField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Link to="/member-main-page">
            <LoginButton>Sign In</LoginButton>
          </Link>
        </form>

        <LoginContainerText>
          Your data will not be shared with any third parties. All data
          collected is used by Darka Forex Wealth for verification purposes
          only. By logging in you agree to our disclaimer.
        </LoginContainerText>

        <Link to="/register-page">
          <RegisterButton className="login__registerButton">
            Create a Darka Forex Account
          </RegisterButton>
        </Link>
      </LoginContainer>
    </LoginRegisterContainer>
  );
}

export default Login;
